import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@client-zone/utils';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LetDirective } from '@ngrx/component';

@NgModule({
  exports: [LetDirective],
  imports: [CommonModule, UtilsModule, LetDirective],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class DataAccessModule {}
