export class ExportMode {
  static readonly power: ExportMode = {
    value: 'POWER',
    translationId: 'exportMode.power',
  };

  static readonly energy: ExportMode = {
    value: 'ENERGY',
    translationId: 'exportMode.energy',
  };

  static readonly quarterHour: ExportMode = {
    value: '15M',
    translationId: 'exportMode.quarterHour',
  };

  static readonly hour: ExportMode = {
    value: '1H',
    translationId: 'exportMode.hour',
  };

  static readonly day: ExportMode = {
    value: '1D',
    translationId: 'exportMode.day',
  };

  static readonly powerQuarterHour: ExportMode = {
    value: 'POWER.15M',
    translationId: 'exportMode.powerQuarterHour',
  };

  static readonly energyQuarterHour: ExportMode = {
    value: 'ENERGY.15M',
    translationId: 'exportMode.energyQuarterHour',
  };

  static readonly energyHour: ExportMode = {
    value: 'ENERGY.1H',
    translationId: 'exportMode.energyHour',
  };

  static readonly energyDay: ExportMode = {
    value: 'ENERGY.1D',
    translationId: 'exportMode.energyDay',
  };

  static readonly unknown = {
    translationId: 'exportMode.unknown',
  };

  value!: string;
  translationId?: string;

  static getAll(): ExportMode[] {
    return [
      this.powerQuarterHour,
      this.energyQuarterHour,
      this.energyHour,
      this.energyDay,
      this.power,
      this.energy,
      this.quarterHour,
      this.hour,
      this.day,
    ];
  }

  static getPower(): ExportMode[] {
    return [this.powerQuarterHour];
  }

  static getEnergy(): ExportMode[] {
    return [this.energyQuarterHour, this.energyHour, this.energyDay];
  }
}
