import { PrimaryRoute } from '@client-zone/data-access';

export class PrimaryRoutes {
  static readonly userInformation: PrimaryRoute = {
    id: 'user-information',
    path: 'user-information',
    translationId: 'navigation.userInformation',
    name: 'Základní informace',
    icon: 'home',
    children: [],
  };

  static readonly electricityDistribution: PrimaryRoute = {
    id: 'electricity-distribution',
    path: 'electricity-distribution',
    translationId: 'navigation.electricityDistribution',
    name: 'Distribuce elektřiny',
    icon: 'offline_bolt',
    children: [
      {
        path: 'transfer-points',
        translationId: 'navigation.transferPoints',
        icon: 'swap_horiz',
        requiresCustomer: false,
        requiresTrader: false,
      },
      // {
      //   path: 'measured-data',
      //   translationId: 'navigation.measuredData',
      //   icon: 'show_chart',
      //   requiresCustomer: false,
      //   requiresTrader: false,
      // },
      {
        path: 'connection-contracts',
        translationId: 'navigation.connectionContracts',
        icon: 'list',
        requiresCustomer: true,
        requiresTrader: false,
      },
      {
        path: 'distribution-contracts',
        translationId: 'navigation.distributionContracts',
        icon: 'list',
        requiresCustomer: false,
        requiresTrader: true,
      },
    ],
  };

  static readonly customerRequests: PrimaryRoute = {
    id: 'customer-requests',
    path: 'customer-requests',
    translationId: 'navigation.customerRequests',
    name: 'Žádosti',
    icon: 'email',
    children: [
      {
        path: 'export',
        translationId: 'navigation.export',
        icon: 'download',
        requiresCustomer: false,
        requiresTrader: false,
      },
    ],
  };

  static getAll(): PrimaryRoute[] {
    return [this.userInformation, this.electricityDistribution, this.customerRequests];
  }

  static defaultRoute(): PrimaryRoute {
    return this.userInformation;
  }
}
