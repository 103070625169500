<!-- Left -->
<div class="grid-area-left">
  <div
    class="czone-flex-row czone-center"
    [ngClass]="{ 'logo-container': drawerMode === 'side', 'symbol-container': drawerMode === 'over' }"
  >
    <ng-container *ngIf="drawerMode === 'side'; else symbolLogo">
      <img [height]="38" [width]="104" src="../../../assets/images/uced-logo-default.svg" alt="" />
    </ng-container>
    <ng-template #symbolLogo>
      <img [height]="38" [width]="38" src="../../../assets/images/uced-symbol-default.png" alt="" />
    </ng-template>
  </div>
</div>

<!-- Center -->
<div class="grid-area-center"></div>

<!-- Right -->
<div class="grid-area-right">
  <button class="menu" aria-label="menu" menu-toggle mat-icon-button color="accent" (click)="toggleDrawer()">
    <mat-icon>menu</mat-icon>
  </button>

  <button class="help" aria-label="help" mat-icon-button [matMenuTriggerFor]="help" color="accent">
    <mat-icon>help</mat-icon>
  </button>

  <button class="account" aria-label="account" mat-icon-button [matMenuTriggerFor]="userSession">
    <mat-icon>account_circle</mat-icon>
  </button>

  <mat-menu direction #help="matMenu" xPosition="after">
    <button class="menu-button" color="secondary" mat-menu-item (click)="openGuide()">
      <mat-icon class="menu-icon">class</mat-icon><span>{{ 'mainHeader.guide' | transloco }}</span>
    </button>
    <button class="menu-button" color="secondary" mat-menu-item [routerLink]="'privacy-policy'">
      <mat-icon class="menu-icon">visibility</mat-icon><span>{{ 'mainHeader.privacyPolicy' | transloco }}</span>
    </button>
    <button class="menu-button" color="secondary" mat-menu-item [routerLink]="'legal-information'">
      <mat-icon class="menu-icon">info</mat-icon><span>{{ 'mainHeader.legalInformation' | transloco }}</span>
    </button>
    <button class="menu-button last-item" color="secondary" mat-menu-item [routerLink]="'contacts'">
      <mat-icon class="menu-icon">phone</mat-icon><span>{{ 'mainHeader.contacts' | transloco }}</span>
    </button>
  </mat-menu>
  <mat-menu direction #userSession="matMenu" xPosition="after"><czone-user-session></czone-user-session></mat-menu>
</div>
