<div class="navigation-list">
  <div *ngFor="let primary of routes">
    <ng-container *ngrxLet="this.navigationService.primaryRouteData$ | async as activePrimaryRoute">
      <a
        class="navigation-item primary-item"
        [ngClass]="{ 'active-section': activePrimaryRoute?.id === primary.id }"
        matRipple
        [routerLink]="primary.path"
        [routerLinkActive]="'item-active'"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon class="czone-center navigation-item-icon"> {{ primary.icon }}</mat-icon>
        <div class="navigation-item-text">
          {{ primary.translationId | transloco }}
        </div>
      </a>

      <ng-container *ngIf="primary.children && activePrimaryRoute?.id === primary.id">
        <ng-container *ngFor="let secondary of primary.children">
          <a
            *ngIf="
              !(
                (secondary.requiresCustomer && !activeRelationship?.isCustomer) ||
                (secondary.requiresTrader && !activeRelationship?.isTrader)
              ) && secondary.path?.length !== 0
            "
            [ngClass]="{ 'active-section': activePrimaryRoute?.id === primary.id }"
            class="navigation-item secondary-item"
            matRipple
            [routerLink]="['/', primary.path, secondary.path]"
            [routerLinkActive]="'item-active'"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <mat-icon *ngIf="secondary.icon" class="czone-center navigation-item-icon"> {{ secondary.icon }}</mat-icon>
            <div class="navigation-item-text">
              {{ secondary.translationId ? (secondary.translationId | transloco) : '' }}
            </div>
          </a>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
