import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { BusinessRelationshipDataService, LoadingService } from '@client-zone/data-access';

@Component({
  selector: 'czone-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  drawerMode: MatDrawerMode = this.getDrawerMode();

  constructor(
    public businessRelationshipDataService: BusinessRelationshipDataService,
    public loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    window.addEventListener('resize', (): void => {
      this.drawerMode = this.getDrawerMode();
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', (): void => {
      this.drawerMode = this.getDrawerMode();
    });
  }

  getDrawerMode(): MatDrawerMode {
    return window.innerWidth > 800 ? 'side' : 'over';
  }
}
