import { Component, InjectionToken, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { AppConfig, BusinessRelationshipDataService } from '@client-zone/data-access';
import { Subject, filter, takeUntil } from 'rxjs';

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');

@Component({
  selector: 'czone-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public businessRelationshipDataService: BusinessRelationshipDataService,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
  ) {
    this.businessRelationshipDataService.getBusinessRelationships();
    this.isIframe = window !== window.parent && !window.opener;
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$),
      )
      .subscribe(() => this.checkAndSetActiveAccount());
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }
}
