import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Address,
  BusinessRelationship,
  BusinessRelationshipDetail,
  BusinessRelationshipsExportRequestsService,
  BusinessRelationshipsService,
  ConnectionContractPagedCollection,
  CreateExportRequest,
  DistributionServicesContractPagedCollection,
  EnergyCounter,
  EnergyProfile,
  ExportRequestHistory,
  ExportRequestMetadata,
  FileHeader,
  FileResponse,
  GridUsageInvoice,
  Part,
  PowerProfile,
  Season,
  TariffEnergyCountersProgress,
  TransferPoint,
  TransferPointPagedCollection,
} from '../api-client.generated';
import { LoadingService } from './loading.service';
import { AlertService, LoggingService, MonthData } from '@client-zone/utils';

export interface TransferPointsFilters {
  businessRelationshipId: string;
  search?: string;
  skip?: number;
  take?: number;
  sort?: string;
}

export interface ConnectionContractsParameters {
  businessRelationshipId: string;
  search?: string;
  skip: number;
  take: number;
  sort?: string;
}

export interface DistributionContractsParameters {
  businessRelationshipId: string;
  search?: string;
  skip: number;
  take: number;
  sort?: string;
}

export interface TransferPointData extends TransferPoint {
  hasProfileData?: boolean;
  hasTariffCounters?: boolean;
}

export interface TransferPointParameters {
  businessRelationshipId: string;
  transferPoint?: TransferPointData;
  powerProfiles: PowerProfilesParameter;
  energyProfiles: EnergyProfilesParameter;
}

export interface PowerProfilesParameter {
  pPlus: boolean;
  pMinus: boolean;
  qiPlus: boolean;
  qcMinus: boolean;
  qiMinus: boolean;
  qcPlus: boolean;
}

export interface EnergyProfilesParameter {
  aPlus: boolean;
  aMinus: boolean;
  rcPlus: boolean;
  rcMinus: boolean;
  riMinus: boolean;
  riPlus: boolean;
}

export interface MonthReportData extends MonthData {
  reports?: Part[];
}

@Injectable({
  providedIn: 'root',
})
export class BusinessRelationshipDataService {
  allRelationships$ = new BehaviorSubject<BusinessRelationship[]>([]);
  activeRelationship$: BehaviorSubject<BusinessRelationship> = new BehaviorSubject({
    id: '',
    name: '',
    isCustomer: false,
    isTrader: false,
    isDistributionSystemOperator: false,
  } as BusinessRelationship);

  constructor(
    private businessRelationshipsService: BusinessRelationshipsService,
    private businessRelationshipsExportRequestsService: BusinessRelationshipsExportRequestsService,
    private alertService: AlertService,
    private loggingService: LoggingService,
    private loadingService: LoadingService,
  ) {}

  getBusinessRelationships(): void {
    this.businessRelationshipsService.getBusinessRelationships().subscribe({
      next: (relationships: BusinessRelationship[]) => {
        this.allRelationships$.next(relationships);
        this.loadingService.appLoaded$.next(true);
        this.activeRelationship$.next(
          relationships.find((relationship) => relationship.id === localStorage.getItem('czone_business')) ??
            relationships[0],
        );
      },
      error: (error: Error) =>
        this.alertService.alert({
          message: `Error while getting business relationships ${JSON.stringify(error)}`,
          type: 'error',
          action: 'Close',
        }),
    });
  }

  setBusinessRelationship(relationshipId: string, relationships: BusinessRelationship[]): void {
    const relationship = relationships.find((relationship) => relationship.id === relationshipId);
    if (relationship) {
      this.loggingService.debug(
        `BusinessRelationshipDataService.setBusinessRelationship`,
        JSON.stringify(relationship),
      );
      this.activeRelationship$.next(relationship);
      localStorage.setItem('czone_business', relationship.id);
    }
  }

  getBusinessAddress(businessRelationshipId: string): Observable<Address[]> {
    return this.businessRelationshipsService.getBusinessRelationshipAddress(businessRelationshipId);
  }

  getBusinessBasicInformation(businessRelationshipId: string): Observable<BusinessRelationshipDetail> {
    return this.businessRelationshipsService.getBusinessRelationshipBasicInformation(businessRelationshipId);
  }

  getBusinessTransferPoints(transferPointsFilters: TransferPointsFilters): Observable<TransferPointPagedCollection> {
    return this.businessRelationshipsService.getBusinessRelationshipTransferPoints(
      transferPointsFilters.businessRelationshipId,
      undefined,
      undefined,
      transferPointsFilters?.sort,
      transferPointsFilters?.search ?? undefined,
      transferPointsFilters?.skip,
      transferPointsFilters?.take,
    );
  }

  getBusinessConnectionContracts(
    connectionContractsParameters: ConnectionContractsParameters,
  ): Observable<ConnectionContractPagedCollection> {
    return this.businessRelationshipsService.getBusinessRelationshipConnectionContracts(
      connectionContractsParameters.businessRelationshipId,
      connectionContractsParameters?.search ?? undefined,
      connectionContractsParameters?.sort,
      connectionContractsParameters?.skip,
      connectionContractsParameters?.take,
    );
  }

  getBusinessDistributionContracts(
    distributionContractsParameters: DistributionContractsParameters,
  ): Observable<DistributionServicesContractPagedCollection> {
    return this.businessRelationshipsService.getBusinessRelationshipDistributionServicesContracts(
      distributionContractsParameters.businessRelationshipId,
      distributionContractsParameters?.search ?? undefined,
      distributionContractsParameters?.sort,
      distributionContractsParameters?.skip,
      distributionContractsParameters?.take,
    );
  }

  getTransferPointEnergyCounters(
    transferPointParameters: TransferPointParameters,
  ): Observable<TariffEnergyCountersProgress> {
    return this.businessRelationshipsService.getTransferPointEnergyCounters(
      transferPointParameters.businessRelationshipId,
      transferPointParameters.transferPoint!.id,
    );
  }

  getInvoicingSeasons(businessRelationshipId: string, transferPointData: TransferPointData): Observable<Season[]> {
    return this.businessRelationshipsService.getInvoicingSeasons(businessRelationshipId, transferPointData.id);
  }

  getGridUsageInvoicing(
    transferPointParameters: TransferPointParameters,
    month: MonthReportData,
  ): Observable<GridUsageInvoice[]> {
    return this.businessRelationshipsService.getGridUsageInvoicing(
      transferPointParameters.businessRelationshipId,
      transferPointParameters.transferPoint!.id,
      month.year,
      month.monthIndex + 1,
    );
  }

  getGridUsageInvoiceEnergyCounters(
    transferPointParameters: TransferPointParameters,
    gridUsageInvoiceId: number,
  ): Observable<EnergyCounter> {
    return this.businessRelationshipsService.getGridUsageInvoiceEnergyCounters(
      transferPointParameters.businessRelationshipId,
      transferPointParameters.transferPoint!.id,
      gridUsageInvoiceId,
    );
  }

  getGridUsageInvoiceProfilesPower(
    transferPointParameters: TransferPointParameters,
    gridUsageInvoiceId: number,
  ): Observable<PowerProfile[]> {
    return this.businessRelationshipsService.getGridUsageInvoiceProfilesPower(
      transferPointParameters.businessRelationshipId,
      transferPointParameters.transferPoint!.id,
      gridUsageInvoiceId,
      this.getProfilesString(transferPointParameters.powerProfiles),
    );
  }

  exportTransferPointProfilesPower(
    transferPointParameters: TransferPointParameters,
    gridUsageInvoiceId: number,
  ): Observable<FileResponse> {
    return this.businessRelationshipsService.exportTransferPointProfilesPower(
      transferPointParameters.businessRelationshipId,
      transferPointParameters.transferPoint!.id,
      gridUsageInvoiceId,
      this.getProfilesString(transferPointParameters.powerProfiles),
    );
  }

  getGridUsageInvoiceProfilesEnergy(
    transferPointParameters: TransferPointParameters,
    gridUsageInvoiceId: number,
    sampling: '15M' | '1H' | '1D',
  ): Observable<EnergyProfile[]> {
    return this.businessRelationshipsService.getGridUsageInvoiceProfilesEnergy(
      transferPointParameters.businessRelationshipId,
      transferPointParameters.transferPoint!.id,
      gridUsageInvoiceId,
      this.getProfilesString(transferPointParameters.energyProfiles),
      sampling,
    );
  }

  exportTransferPointProfilesEnergy(
    transferPointParameters: TransferPointParameters,
    gridUsageInvoiceId: number,
    sampling: string,
  ): Observable<FileResponse> {
    return this.businessRelationshipsService.exportTransferPointProfilesEnergy(
      transferPointParameters.businessRelationshipId,
      transferPointParameters.transferPoint!.id,
      gridUsageInvoiceId,
      this.getProfilesString(transferPointParameters.energyProfiles),
      sampling,
    );
  }

  exportTransferPointEnergyCounters(transferPointParameters: TransferPointParameters): Observable<FileResponse> {
    return this.businessRelationshipsService.exportTransferPointEnergyCounters(
      transferPointParameters.businessRelationshipId,
      transferPointParameters.transferPoint!.id,
    );
  }

  createExportRequest(businessRelationshipId: string, request: CreateExportRequest): Observable<FileHeader> {
    return this.businessRelationshipsExportRequestsService.createExportRequest(businessRelationshipId, request);
  }

  getExportRequests(businessRelationshipId: string): Observable<FileHeader[]> {
    return this.businessRelationshipsExportRequestsService.getExportRequests(businessRelationshipId);
  }

  downloadExportRequest(businessRelationshipId: string, requestId: string): Observable<FileResponse> {
    return this.businessRelationshipsExportRequestsService.downloadExportRequest(businessRelationshipId, requestId);
  }

  deleteExportRequest(businessRelationshipId: string, requestId: string): Observable<boolean> {
    return this.businessRelationshipsExportRequestsService.deleteExportRequest(businessRelationshipId, requestId);
  }

  getExportRequestMetadata(businessRelationshipId: string, requestId: string): Observable<ExportRequestMetadata> {
    return this.businessRelationshipsExportRequestsService.getExportRequestMetadata(businessRelationshipId, requestId);
  }

  getExportRequestStateHistory(businessRelationshipId: string, requestId: string): Observable<ExportRequestHistory[]> {
    return this.businessRelationshipsExportRequestsService.getExportRequestHistory(businessRelationshipId, requestId);
  }

  getProfilesString(
    profiles: PowerProfilesParameter | EnergyProfilesParameter | { [s: string]: unknown } | ArrayLike<unknown>,
  ): string {
    return Object.getOwnPropertyNames(
      Object.fromEntries(Object.entries(profiles).filter(([, value]) => value === true)),
    )
      .toString()
      .toUpperCase();
  }
}
