import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { BusinessRelationship, BusinessRelationshipDataService } from '@client-zone/data-access';
import { PrimaryRoutes } from 'src/app/routes';

@Component({
  selector: 'czone-user-session',
  templateUrl: './user-session.component.html',
  styleUrls: ['./user-session.component.scss'],
})
export class UserSessionComponent implements OnInit {
  activeAccount: AccountInfo | undefined | null;

  constructor(
    public businessRelationshipDataService: BusinessRelationshipDataService,
    private msalService: MsalService,
    public router: Router,
  ) {
    this.activeAccount = msalService.instance.getActiveAccount();
  }

  ngOnInit(): void {
    this.activeAccount = this.msalService.instance.getActiveAccount();
  }

  onBusinessRelationshipSelected(e: { value: string }, relationships: BusinessRelationship[]) {
    this.router.navigate([PrimaryRoutes.defaultRoute().path]);
    this.businessRelationshipDataService.setBusinessRelationship(e.value, relationships);
  }

  logout(): void {
    this.msalService.logout();
  }
}
