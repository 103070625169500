export class MeteringType {
  static readonly a: MeteringType = {
    value: 'A',
    text: 'A',
  };
  static readonly b: MeteringType = {
    value: 'B',
    text: 'B',
  };
  static readonly c: MeteringType = {
    value: 'C',
    text: 'C',
  };

  readonly value!: 'A' | 'B' | 'C';
  readonly text?: string;

  static getAll(): MeteringType[] {
    return [this.a, this.b, this.c];
  }
}
