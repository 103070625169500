import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  constructor() {}

  appLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  transferPointDetailLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  seasonsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  tariffCountersLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
}
