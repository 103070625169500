<div class="czone-main-spacing">
  <h1 class="title">{{ 'userSettings.title' | transloco }}</h1>
  <ng-container *ngIf="languageSelectionViewModel$ | async as languageSelectionViewModel">
    <div class="czone-flex-column czone-gap-x-small user-settings-container">
      <h2 class="label">{{ 'userSettings.localization' | transloco }}</h2>
      <mat-form-field appearance="fill" [subscriptSizing]="'dynamic'">
        <mat-select
          (selectionChange)="localizationService.setLanguage($event.value)"
          [value]="languageSelectionViewModel.selectedLanguage"
        >
          <mat-option *ngFor="let item of languageSelectionViewModel.languages" [value]="item.value">
            {{ item.translationId | transloco }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
</div>
